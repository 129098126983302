$(function () {
    $.validator.addMethod("documento", function (value, element) {

        // remove pontuações
        value = value.replace('.', '');
        value = value.replace('.', '');
        value = value.replace('-', '');
        value = value.replace('/', '');

        if (value.length <= 11) {
            if ($.validator.methods.cpf.call(this, value, element)) {
                return true;
            } else {
                this.settings.messages.comprador_documento.documento = "Informe um CPF válido.";
            }
        } else if (value.length <= 14) {
            if ($.validator.methods.cnpj.call(this, value, element)) {
                return true;
            } else {
                this.settings.messages.comprador_documento.documento = "Informe um CNPJ válido.";
            }
        }

        return false;

    }, "Informe um CPF ou CNPJ válido.");


    // validação do CPF
    $.validator.addMethod("cpf", function (value, element) {
        value = $.trim(value);

        value = value.replace('.', '');
        value = value.replace('.', '');
        cpf = value.replace('-', '');
        while (cpf.length < 11) cpf = "0" + cpf;
        var expReg = /^0+$|^1+$|^2+$|^3+$|^4+$|^5+$|^6+$|^7+$|^8+$|^9+$/;
        var a = [];
        var b = 0;
        var c = 11;
        for (i = 0; i < 11; i++) {
            a[i] = cpf.charAt(i);
            if (i < 9) b += (a[i] * --c);
        }
        if ((x = b % 11) < 2) {
            a[9] = 0;
        } else {
            a[9] = 11 - x;
        }
        b = 0;
        c = 11;
        for (y = 0; y < 10; y++) b += (a[y] * c--);
        if ((x = b % 11) < 2) {
            a[10] = 0;
        } else {
            a[10] = 11 - x;
        }

        var retorno = true;
        if ((cpf.charAt(9) != a[9]) || (cpf.charAt(10) != a[10]) || cpf.match(expReg)) retorno = false;

        return this.optional(element) || retorno;

    }, "Informe um CPF válido.");


    // validação do CNPJ
    $.validator.addMethod("cnpj", function (cnpj, element) {
        cnpj = $.trim(cnpj);// retira espaços em branco
        // DEIXA APENAS OS NÚMEROS
        cnpj = cnpj.replace('/', '');
        cnpj = cnpj.replace('.', '');
        cnpj = cnpj.replace('.', '');
        cnpj = cnpj.replace('-', '');

        var numeros, digitos, soma, i, resultado, pos, tamanho, digitos_iguais;
        digitos_iguais = 1;

        if (cnpj.length < 14 && cnpj.length < 15) {
            return false;
        }
        for (i = 0; i < cnpj.length - 1; i++) {
            if (cnpj.charAt(i) != cnpj.charAt(i + 1)) {
                digitos_iguais = 0;
                break;
            }
        }

        if (!digitos_iguais) {
            tamanho = cnpj.length - 2;
            numeros = cnpj.substring(0, tamanho);
            digitos = cnpj.substring(tamanho);
            soma = 0;
            pos = tamanho - 7;

            for (i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2) {
                    pos = 9;
                }
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0)) {
                return false;
            }
            tamanho = tamanho + 1;
            numeros = cnpj.substring(0, tamanho);
            soma = 0;
            pos = tamanho - 7;
            for (i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--;
                if (pos < 2) {
                    pos = 9;
                }
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1)) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }, "Informe um CNPJ válido."); // Mensagem padrão
});

$(function () {
    var maskTelefone = function (val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 000.00.0000' : '(00) 0000.00009';
        },
        optionsTelefone = {
            onKeyPress: function (val, e, field, options) {
                field.mask(maskTelefone.apply({}, arguments), options);
            }
        };
    $('.input-telefone').mask(maskTelefone, optionsTelefone);

    var maskDocumento = function (val) {
            return val.replace(/\D/g, '').length > 11 ? '00.000.000/0000-00' : '000.000.000-009';
        },
        optionsDocumento = {
            onKeyPress: function (val, e, field, options) {
                field.mask(maskDocumento.apply({}, arguments), options);
            }
        };
    $('.input-documento').mask(maskDocumento, optionsDocumento);
});


$(document).ready(function () {

    // menu
    $(document).on('scroll', function (ev) {
        if (window.pageYOffset > 200) {
            $('.header').addClass('sticky');
        } else {
            $('.header').removeClass('sticky');
        }
    })

    $(document).on('click touchstart', '.header__open', function (ev) {
        ev.preventDefault();
        $('.header__nav').addClass('active')
    })
    $(document).on('click touchstart', '.header__close', function (ev) {
        ev.preventDefault();
        $('.header__nav').removeClass('active')
    })

    if ($('.banner.swiper-container').length) {
        var mySwiper = new Swiper('.banner.swiper-container', {
            // Optional parameters
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    }

    if ($('.vila__carousel .swiper-container').length) {
        var mySwiper = new Swiper('.vila__carousel .swiper-container', {
            // Optional parameters
            loop: true,
            autoplay: true,
            autoHeight: true, //enable auto height
            pagination: {
                el: '.vila__carousel .swiper-pagination',
                clickable: true
            },

        })
    }

    if ($('.destaque-swiper.swiper-container').length) {
        var mySwiper = new Swiper('.destaque-swiper.swiper-container', {
            // Optional parameters
            loop: true,
            autoplay: true,
            pagination: {
                el: '.destaque .swiper-pagination',
                clickable: true
            }

        })
    }
    if ($('.vinicola .swiper-container').length) {
        var mySwiper = new Swiper('.vinicola .swiper-container', {
            // Optional parameters
            loop: true,
            autoplay: true,
            pagination: {
                el: '.vinicola .swiper-pagination',
                clickable: true
            }

        })
    }
    if ($('.noticia__thumb .swiper-container').length) {
        var mySwiper = new Swiper('.noticia__thumb .swiper-container', {
            // Optional parameters
            loop: true,
            autoplay: true,
            pagination: {
                el: '.noticia__thumb .swiper-pagination',
                clickable: true
            }

        })
    }

    // MODAL MODAL MODAL
    $(document).on('click touchstart', '[data-modal-target]', function (ev) {
        ev.preventDefault()
        var modal = $(this).attr('data-modal-target')
        $('[data-modal=' + modal + ']').addClass('active')
    })
    $(document).on('click touchstart', '[data-modal-close]', function (ev) {
        ev.preventDefault()
        var modal = $(this).attr('data-modal-close')
        $('[data-modal=' + modal + ']').removeClass('active')
    })
    $(document).on('keyup', function (ev) {
        if (ev.keyCode == 27) {
            $('.modal').removeClass('active')
        }
    });
    $(document).on('click touchstart', '.modal', function (ev) {
        if ($(ev.target).hasClass('modal')) {
            $(ev.target).removeClass('active')
        }
    })

    // FORM FORM FORM

    $(document).on('focus', '.form-control--floating-label input, .form-control--floating-label textarea', function () {
        $(this).parent().addClass('active')
    })
    $(document).on('blur', '.form-control--floating-label input, .form-control--floating-label textarea', function () {
        if (!$(this).val().length) {
            $(this).parent().removeClass('active')
        }
    })

})